import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { SaveIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import useChangePassword from '../../hooks/useChangePassword';
import { useAuth } from '../../hooks/useAuth';
import Alert from '../shared/Alert';
import { passwordSchema } from '../../utils/passwords';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';

const AccountSettings = ({ setDidChangePassword }) => {
  const { user: currentUser } = useAuth();
  const setBannerMessage = useContext(InformationBannerContext);
  const history = useHistory();

  // Form handler
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    context: { username: currentUser.email },
  });

  // API handler
  const mutation = useChangePassword(currentUser.id, () => {
    setDidChangePassword(true);
    history.push(`/`);
    setBannerMessage('Updated account settings successfully.');
  });

  return (
    <>
      {mutation.data && (
        <Alert message="Your password was changed successfully." />
      )}
      <Form
        title="Account Settings"
        description="Manage details for your account below."
        onSubmit={handleSubmit((data) => {
          mutation.mutate(data);
        })}
        cancelLink="/"
        submitText="Update"
        submitIcon={<SaveIcon />}
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={{
          message:
            'A problem occurred. Please confirm your current password is correct. If this error persists, please contact Support',
        }}
      >
        <FormTextField
          register={register}
          error={errors.current_password}
          title="Current Password"
          formKey="current_password"
          type="password"
        />

        <FormTextField
          register={register}
          error={errors.password}
          title="New Password"
          formKey="password"
          type="password"
        />

        <FormTextField
          register={register}
          error={errors.passwordConfirmation}
          title="New Password Confirmation"
          formKey="passwordConfirmation"
          type="password"
        />
      </Form>
    </>
  );
};

AccountSettings.propTypes = {
  setDidChangePassword: PropTypes.func.isRequired,
};

export default AccountSettings;
