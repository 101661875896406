/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

const MIN_PASSWORD_LENGTH = 8;

const passwordNotUsername = function (value) {
  // Check if context and username exist before comparison
  if (this.options.context && this.options.context.username) {
    return value !== this.options.context.username;
  }
  // If context or username is not available, consider the test failed
  return false;
};

export const passwordSchema = yup.object().shape({
  current_password: yup.string().required(),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).+$/,
      'Password must contain at least one letter and one number.'
    )
    .test(
      'password-not-username',
      'Password cannot be the same as username',
      passwordNotUsername
    )
    .required(),
  passwordConfirmation: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .oneOf(
      [yup.ref('password'), null],
      'Password confirmation must match password.'
    )
    .required('Password confirm is required'),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).+$/,
      'Password must contain at least one letter and one number.'
    )
    .test(
      'password-not-username',
      'Password cannot be the same as username',
      passwordNotUsername
    )
    .required(),
  passwordConfirmation: yup
    .string()
    .min(
      MIN_PASSWORD_LENGTH,
      'Password confirmation must be at least 8 characters'
    )
    .oneOf(
      [yup.ref('password'), null],
      'Password confirmation must match password.'
    )
    .required('Password confirm is required'),
});
